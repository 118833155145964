import { ChooseExamplesDialog } from '@gtn/app-common/components/choose-examples/ChooseExamplesDialog';
import ChoosePortfolioItemsDialog from '@gtn/app-common/components/portfolios/choose-portfolio-items-dialog/ChoosePortfolioItemsDialog';
import CreateEditPortfolioDialog from '@gtn/app-common/components/portfolios/create-edit-portfolio-dialog/CreateEditPortfolioDialog';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import AddPortfolioTextBlockDialog from '@gtn/app-common/components/portfolios/add-portfolio-text-block-dialog/AddPortfolioTextBlockDialog';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import { useSelectedCourse } from '@gtn/app-common/store/app.store.hooks';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainerV2';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { IconButton } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  ArchiveOutlined,
  DeleteForeverOutlined,
  DescriptionOutlined,
  ImageOutlined,
  InsertDriveFileOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NotesOutlined,
  PictureAsPdfOutlined,
  SlideshowOutlined,
  TableChartOutlined,
  ViewHeadlineOutlined,
} from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './Portfolios.module.scss';
import { block_exaport_view_details_returns_blocks, block_exaport_view_details_returns_blocks_type } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';
import AddPortfolioHeadlineBlockDialog from '@gtn/app-common/components/portfolios/add-portfolio-headline-block-dialog/AddPortfolioHeadlineBlockDialog';

const fileIcons = {
  image: <ImageOutlined />,
  pdf: <PictureAsPdfOutlined />,
  doc: <DescriptionOutlined />,
  docx: <DescriptionOutlined />,
  xls: <TableChartOutlined />,
  xlsx: <TableChartOutlined />,
  ppt: <SlideshowOutlined />,
  pptx: <SlideshowOutlined />,
  txt: <NotesOutlined />,
  zip: <ArchiveOutlined />,
  rar: <ArchiveOutlined />,
  csv: <TableChartOutlined />,
  default: <InsertDriveFileOutlined />,
};

export default function Portfolios() {
  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();

  const [selectedPortfolioId, setSelectedPortfolioId] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [sortingDetails, setSortingDetails] = useState<boolean>(false);
  const [draggingDetailBlock, setDraggingDetailBlock] = useState<block_exaport_view_details_returns_blocks | undefined>(undefined);

  const chooseExamplesDialog = useGtnDialog(ChooseExamplesDialog);
  const createEditPortfolioDialog = useGtnDialog(CreateEditPortfolioDialog);
  const chooseItemsDialog = useGtnDialog(ChoosePortfolioItemsDialog);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);
  const addPortfolioTextDialog = useGtnDialog(AddPortfolioTextBlockDialog);
  const addPortfolioHeadlineDialog = useGtnDialog(AddPortfolioHeadlineBlockDialog);

  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const { data: portfolios, progressState: portfoliosProgressState, mutate: reloadPortfolios } = useAPI(moodleAPI.block_exaport_view_list);

  const {
    data: portfolioDetails,
    progressState: portfolioDetailsProgressState,
    mutate: reloadPortfolioDetails,
  } = useAPI(
    moodleAPI.block_exaport_view_details,
    [
      {
        id: selectedPortfolioId,
      },
    ],
    {
      enabled: !!selectedPortfolioId,
    }
  );

  useEffect(() => {
    setSelectedPortfolioId(0);
  }, [selectedCourse]);

  useEffect(() => {
    if (portfolios && portfolios.length > 0 && !selectedPortfolioId) {
      setSelectedPortfolioId(portfolios[0].id ?? 0);
    }
  }, [portfolios]);

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (e: React.DragEvent<HTMLElement>, block: block_exaport_view_details_returns_blocks) => {
    e.preventDefault();
    onDragEnd(e);

    const oldBlocks = portfolioDetails?.blocks;
    if (!oldBlocks || !draggingDetailBlock) {
      return;
    }
    const newBlocks = oldBlocks.map((block) => block.id);
    const targetIndex = oldBlocks.indexOf(block);
    const sourceIndex = oldBlocks.indexOf(draggingDetailBlock);
    const [blockId] = newBlocks.splice(sourceIndex, 1);
    newBlocks.splice(targetIndex, 0, blockId);
    sortPortfolioDetailBlocks(newBlocks);
  };

  const onDragStart = (e: React.DragEvent<HTMLElement>) => {
    e.dataTransfer.effectAllowed = 'move';

    const portfolioDetailBlock = portfolioDetails?.blocks.find((block) => block.id === Number((e.target as HTMLElement).id));
    if (portfolioDetailBlock) {
      setDraggingDetailBlock(portfolioDetailBlock);
    }
  };

  const onDragEnd = (e: React.DragEvent<HTMLElement>) => {
    setDraggingDetailBlock(undefined);
  };

  async function addItemsToPortfolio(itemIds: number[]) {
    chooseItemsDialog.close();
    await moodleAPI.block_exaport_view_block_add_multiple({
      viewid: portfolioDetails!.id,
      itemids: itemIds,
    });

    reloadPortfolioDetails();
  }

  function sortPortfolioDetailBlocks(blockIds: number[]) {
    setSortingDetails(true);
    moodleAPI
      .block_exaport_view_block_sorting({ viewid: selectedPortfolioId, blockids: blockIds })
      .then(() => {
        reloadPortfolioDetails();
      })
      .finally(() => {
        setSortingDetails(false);
      });
  }

  function renderSubtitle(block: block_exaport_view_details_returns_blocks) {
    return block.files.map((file, index) => {
      return (
        <div className={styles.blockSubTitlePart}>
          {fileIcon(file.filename)} {filenameWoExt(file.filename)}
          {index < block.files.length - 1 && <span style={{ fontSize: '2rem', lineHeight: '0.7' }}>&middot;</span>}
        </div>
      );
    });
  }

  function filenameWoExt(filename: string) {
    return filename.split('.')[0];
  }

  function fileIcon(filename: string) {
    if (!filename) {
      return fileIcons.default;
    }

    const extension = filename.split('.').pop()?.toLowerCase();
    if (!extension) {
      return fileIcons.default;
    }

    const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'tiff'];
    if (imageExtensions.includes(extension)) {
      return fileIcons['image'];
    }

    return fileIcons[extension] || fileIcons.default;
  }

  function renderItemContent(block: block_exaport_view_details_returns_blocks) {
    switch (block.type) {
      case block_exaport_view_details_returns_blocks_type.TEXT:
        return (
          <>
            <NotesOutlined style={{ fill: '#5D5D5D' }} className={styles.icon} />
            <ListItemText className={styles.portfolioDetailBlock} primary={block.title} secondary={<span title={block.text}>{block.text}</span>} />
          </>
        );
      case block_exaport_view_details_returns_blocks_type.HEADLINE:
        return (
          <>
            <ViewHeadlineOutlined style={{ fill: '#5D5D5D' }} className={styles.icon} />
            <ListItemText primary={block.title} />
          </>
        );
      default:
      case block_exaport_view_details_returns_blocks_type.OTHER:
      case block_exaport_view_details_returns_blocks_type.ITEM:
        return (
          <>
            <img className={styles.icon} src="img/example.svg" />
            <ListItemText className={styles.portfolioDetailBlock} primary={block.title} secondary={<span title={block.files.map((f) => f.filename).join(' - ') ?? ''}>{renderSubtitle(block)}</span>} />
          </>
        );
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.portfoliosContainer}>
          <LoadingContainer state={portfoliosProgressState} style={{ gap: 12 }}>
            {portfolios?.map((portfolio) => (
              <ListItem
                className={classNames(styles.listItem, {
                  [styles.selected]: selectedPortfolioId == portfolio.id,
                })}
                onClick={() => setSelectedPortfolioId(portfolio.id)}
                button
                key={'learning-path-' + portfolio.id}
              >
                <ListItemText primary={portfolio.name} secondary={portfolio.description} />
              </ListItem>
            ))}
          </LoadingContainer>

          <p className={styles.addExamples}>
            <GtnButton
              label={t('portfolios.add')}
              actionType="primary"
              onClick={async () =>
                createEditPortfolioDialog.open({
                  onClose: () => reloadPortfolios(),
                })
              }
            />
          </p>
        </div>
        {!!selectedPortfolioId && (
          <LoadingContainer
            state={portfolioDetailsProgressState}
            style={{
              flexGrow: 1,
              padding: 'var(--page-padding)',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            {!!portfolioDetails && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <h2>{portfolioDetails.name}</h2>
                    <p>{portfolioDetails.description}</p>
                  </div>

                  {portfolioDetails.external_url && (
                    <>
                      <GtnButton
                        actionType="primary"
                        label={t('portfolios.open')}
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          window.open(portfolioDetails.external_url!, '_blank');
                        }}
                      />
                      {/*
                      <GtnButton
                        actionType="primary"
                        label={t('portfolios.generate-pdf')}
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          window.open(portfolioDetails.external_url! + '&aspdf=1', '_blank');
                        }}
                      />
                      */}
                      <GtnButton
                        actionType="primary"
                        label={t('portfolios.copy-url')}
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          try {
                            navigator.clipboard.writeText(portfolioDetails.external_url!);
                            setSnackbarMessage(t('portfolios.copy-url-success'));
                          } catch (e) {
                            GtnLogger.warn(e);
                          }
                        }}
                      />
                    </>
                  )}
                  <GtnButton label={t('portfolios.layout-settings')} style={{ marginRight: 8 }} onClick={() => window.open(portfolioDetails.advanced_url, 'new')} />
                  <GtnButton
                    label={t('portfolios.edit')}
                    style={{ marginRight: 8 }}
                    onClick={async () =>
                      createEditPortfolioDialog.open({
                        portfolio: portfolioDetails,
                        onClose: () => {
                          reloadPortfolios();
                          reloadPortfolioDetails();
                        },
                      })
                    }
                  />
                  <GtnButton
                    label={t('portfolios.delete')}
                    style={{ marginRight: 8 }}
                    onClick={async () => {
                      if (window.confirm(t('portfolios.confirm-delete'))) {
                        await moodleAPI.block_exaport_view_delete({ id: portfolioDetails.id });
                        setSelectedPortfolioId(0);
                        reloadPortfolios();
                      }
                    }}
                  />
                </div>
                {sortingDetails ? (
                  <LoadingIndicatorInline />
                ) : (
                  <div className={styles.learningPathExamplesContainer} onDragOver={onDragOver}>
                    {portfolioDetails.blocks.map((block, index) => {
                      return (
                        <ListItem
                          className={classNames(styles.listItem, {})}
                          style={{
                            flexGrow: 1,
                          }}
                          draggable
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                          onDrop={(e) => onDrop(e, block)}
                          onDragOver={onDragOver}
                          id={String(block.id)}
                        >
                          <div style={{ width: 20 }}>{index + 1}.</div>
                          {renderItemContent(block)}
                          <div className={classNames(styles.moveArrows, styles.itemButton)}>
                            <IconButton
                              disabled={index === 0}
                              style={{ padding: 0 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                const newBlocks = portfolioDetails.blocks.map((block) => block.id);
                                const targetIndex = index - 1;
                                const [blockId] = newBlocks.splice(index, 1);
                                newBlocks.splice(targetIndex, 0, blockId);
                                sortPortfolioDetailBlocks(newBlocks);
                              }}
                            >
                              <KeyboardArrowUp />
                            </IconButton>
                            <IconButton
                              disabled={index === portfolioDetails.blocks.length - 1}
                              style={{ padding: 0 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                const newBlocks = portfolioDetails.blocks.map((block) => block.id);
                                const targetIndex = index + 1;
                                const [blockId] = newBlocks.splice(index, 1);
                                newBlocks.splice(targetIndex, 0, blockId);
                                sortPortfolioDetailBlocks(newBlocks);
                              }}
                            >
                              <KeyboardArrowDown />
                            </IconButton>
                          </div>
                          <IconButton
                            className={styles.itemButton}
                            onClick={async (event) => {
                              event.stopPropagation();
                              if (!window.confirm(t('portfolios.remove-example'))) {
                                return;
                              }

                              await moodleAPI.block_exaport_view_block_delete({
                                viewid: portfolioDetails.id,
                                blockid: block.id,
                              });

                              reloadPortfolioDetails();
                            }}
                          >
                            <DeleteForeverOutlined />
                          </IconButton>
                        </ListItem>
                      );
                    })}

                    {portfolioDetails.blocks.length === 0 && (
                      <p>
                        <i>{t('portfolios.no-items')}</i>
                      </p>
                    )}

                    <p className={styles.addExamples}>
                      <GtnButton
                        label={t('portfolios.add-headline')}
                        actionType="primary"
                        onClick={async () => {
                          addPortfolioHeadlineDialog.open({
                            viewid: portfolioDetails.id,
                            onClose: () => reloadPortfolioDetails(),
                          });
                        }}
                      />
                      <GtnButton
                        label={t('portfolios.add-text')}
                        actionType="primary"
                        onClick={async () => {
                          addPortfolioTextDialog.open({
                            viewid: portfolioDetails.id,
                            onClose: () => reloadPortfolioDetails(),
                          });
                        }}
                      />
                      <GtnButton
                        label={t('portfolios.add-items')}
                        actionType="primary"
                        onClick={async () => {
                          chooseItemsDialog.open({
                            onSelected: (itemIds) => addItemsToPortfolio(itemIds),

                            title: t('portfolios.items'),
                          });
                        }}
                      />
                    </p>
                  </div>
                )}
              </>
            )}
          </LoadingContainer>
        )}
      </div>

      <chooseExamplesDialog.Component />
      <addPortfolioTextDialog.Component />
      <addPortfolioHeadlineDialog.Component />
      <createEditPortfolioDialog.Component />
      <exampleItemDialog.Component onSave={() => reloadPortfolioDetails()} />
      <chooseItemsDialog.Component />
      <GtnSnackbar message={snackbarMessage} open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} />
    </>
  );
}
