import { getCommonRoutes } from '@gtn/app-common/CommonRoutes';
import { AppNavigation } from '@gtn/app-common/components/app-navigation/AppNavigation';
import CompetenceProfile from '@gtn/app-common/components/competence-profile/CompetenceProfile';
import Examples from '@gtn/app-common/components/examples/Examples';
import Portfolios from '@gtn/app-common/components/portfolios/Portfolios';
import Workbook from '@gtn/app-common/components/workbook/Workbook';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import CourseTopics from '@gtn/app-common/routes/course-topics/CourseTopics';
import { useServerInfoManager } from '@gtn/app-common/store/app.store.hooks';
import { NavigationItem } from '@gtn/common/components/navigation/NavigationItem';
import { useIsTeacher, useRole } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import { DakoraRoutingPaths } from '@gtn/dakora/routes/DakoraRouting';
import LearningPaths from '@gtn/dakora/routes/learning-paths/LearningPaths';
import { DakoraExacompVersions } from '@gtn/dakora/utils/DakoraExacompVersions';
import { DiggrSettings } from '@gtn/diggr/components/settings/DiggrSettings';
import { Assessment, Folder, FolderShared } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Redirect } from 'react-router-dom';

export function DiggrPlusApp() {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const userRole = useRole();
  const serverInfoManager = useServerInfoManager();

  const routes: GtnRoute[] = [
    {
      path: CommonRoutingPaths.HOME,
      exact: true,
      content: () => <Redirect to={`/${CommonRoutingPaths.WORKBOOK}`} />,
    },
    {
      path: `/${CommonRoutingPaths.WORKBOOK}`,
      exact: true,
      content: () => <Workbook showTypeFilter={true} />,
      title: `menu.workbook.${userRole}`,
    },
    {
      path: `/${CommonRoutingPaths.COMPETENCE_PROFILE}`,
      content: () => <CompetenceProfile />,
      title: `menu.competencies.${userRole}`,
    },
    {
      path: `/${DakoraRoutingPaths.PORTFOLIOS}`,
      content: () => <Portfolios />,
      title: `menu.portfolios.${userRole}`,
    },
    {
      path: `/${CommonRoutingPaths.EXAMPLES}`,
      content: () => <Examples />,
      title: `menu.examples.teacher`,
      isAccessible: isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.COURSE_TOPICS}`,
      content: () => <CourseTopics />,
      title: `settings.choose-competencies`,
      backRoute: `/${CommonRoutingPaths.SETTINGS}`,
      isAccessible: isTeacher,
    },
    {
      path: `/${CommonRoutingPaths.SETTINGS}`,
      content: () => <DiggrSettings />,
      title: 'menu.settings',
    },
    ...getCommonRoutes(),
  ];

  const navItems: NavigationItem[] = [
    {
      title: t.withRole('menu.workbook'),
      href: CommonRoutingPaths.WORKBOOK,
      icon: <FolderShared />,
    },
    {
      title: t.withRole('menu.competencies'),
      href: CommonRoutingPaths.COMPETENCE_PROFILE,
      icon: <Assessment />,
    },
    {
      title: t.withRole('menu.examples'),
      href: CommonRoutingPaths.EXAMPLES,
      icon: <AssignmentIcon />,
      hide: !isTeacher,
    },
    ...(!isTeacher && serverInfoManager.getMoodlePluginVersion('block_exaport') >= DakoraExacompVersions.EXAPORT_MIN_VERSION_PORTFOLIOS
      ? [
          {
            title: t.withRole('menu.portfolios'),
            href: DakoraRoutingPaths.PORTFOLIOS,
            icon: <Folder />,
          },
        ]
      : []),
  ];

  return <AppNavigation routes={routes} primaryNavigationItems={navItems} />;
}
