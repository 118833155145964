import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { GradingTreeDescriptor, GradingTreeResponse, GradingTreeTopic } from '@gtn/app-common/api/model/CompetenceProfile';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import styles from '@gtn/app-common/components/competence-profile/CompetenceProfile.module.scss';
import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import { useAppCommonSelector, useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GTN_SELECT_DEFAULT_OPTION } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnTreeNode, GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import IframeDialog from '@gtn/common/components/IframeDialog';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { UserRole } from '@gtn/common/store/user/user.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import React, { useMemo, useState } from 'react';

export default function CompetenceProfile() {
  const t = useAppTranslation();
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const isTeacher = useIsTeacher();
  const selectedCourse = useSelectedCourse(); // only used when user is teacher
  const selectedStudent = useSelectedStudent(); // only used when user is teacher
  const moodleUrl = useAppCommonSelector((state) => state.preferences.moodleUrl);

  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const [gradingErrorSnackbarOpen, setGradingErrorSnackbarOpened] = useState(false);

  const iframeDialog = useGtnDialog(IframeDialog);

  const { data: competenceProfileData, mutate: refresh } = useAPI(appCommonAPI.getUserCompetenceProfile, [selectedCourse?.id!!, selectedStudent?.id], {
    useCache: false,
  });

  const filteredCompetencies = useMemo(() => {
    const competencies = competenceProfileData?.competencetree;

    if (searchFilter) {
      return Utils.filterTree<GradingTreeResponse, GradingTreeTopic>(
        competencies,
        'topics',
        (gradingTree) => Utils.includesSearch(gradingTree.title, searchFilter),
        (topics) => {
          return Utils.filterTree<GradingTreeTopic, GradingTreeDescriptor>(
            topics,
            'descriptors',
            (element) => Utils.includesSearch(element.title, searchFilter),
            (descriptors) => descriptors.filter((descriptor) => Utils.includesSearch(descriptor.title, searchFilter))
          );
        }
      );
    } else {
      return competencies;
    }
  }, [competenceProfileData, searchFilter]);

  const initialFormValues = useMemo(() => {
    const initialValues = {};
    competenceProfileData?.competencetree
      .flatMap((gradingTree) =>
        gradingTree.topics.flatMap((topic) => topic.descriptors.flatMap((descriptor) => (descriptor.childdescriptors ? [descriptor, ...descriptor.childdescriptors] : descriptor)))
      )
      ?.forEach((descriptor) => (initialValues[descriptor.id] = descriptor.teacherevaluation));
    return initialValues;
  }, [competenceProfileData]);

  const renderProgress = (text: string, color: string, value?: number, max?: number) => {
    const width = value && max ? (value / max) * 100 : 0;
    return (
      <div className={styles.progressContainer}>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: width + '%', backgroundColor: color }} />
          <p className={styles.value} style={{ color: width < 6 ? 'black' : undefined }}>
            {value}
          </p>
          <p className={styles.max}>{t('competence-profile.progress.max', { num: max })}</p>
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    );
  };

  const saveTeacherGrading = async (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    try {
      const descriptorId = Number(e.target.name);
      const grading = e.target.value !== GTN_SELECT_DEFAULT_OPTION ? Number(e.target.value) : -1;

      const courseId = competenceProfileData?.competencetree.find((subject) =>
        subject.topics.some((topic) => topic.descriptors.some((descriptor) => descriptor.id === descriptorId || descriptor.childdescriptors?.some((cd) => cd.id === descriptorId)))
      )?.courseid;

      if (courseId) {
        await appCommonAPI.gradeDescriptor({
          descriptorId: descriptorId,
          grading: grading,
          userId: selectedStudent?.id!!,
          courseId: courseId,
          role: UserRole.Teacher,
        });
        await refresh();
      }
    } catch (e) {
      GtnLogger.warn(e);
      setGradingErrorSnackbarOpened(true);
    }
  };

  const renderGrading = (descriptor: GradingTreeDescriptor) => {
    return (
      <>
        <GradingInput readonly={true} gradingType="student" grading={descriptor.studentevaluation} className={styles.studentEvaluation} />
        <GradingInput
          readonly={!isTeacher}
          gradingType="teacher"
          name={String(descriptor.id)}
          className={styles.teacherEvaluation}
          onChange={saveTeacherGrading}
          grading={!isTeacher ? descriptor.teacherevaluation : undefined}
        />
      </>
    );
  };

  const renderDescriptor = (descriptor: GradingTreeDescriptor) => {
    return (
      <div key={descriptor.id} className={styles.descriptorContainer}>
        <p dangerouslySetInnerHTML={Utils.highlightSearchText(descriptor.title, searchFilter)} />

        {renderGrading(descriptor)}
      </div>
    );
  };

  function getTextWithCount(text: string, count: number, total: number) {
    return `${text} <i>(${count}/${total})</i>`;
  }

  const mainContent = (
    <>
      <h3 className={styles.title}>{t.withRole('competence-profile.progress.title')}</h3>
      {!competenceProfileData && <LoadingIndicatorInline />}

      {competenceProfileData && (
        <>
          {renderProgress(t('competence-profile.progress.solved-items'), '#7DC673', competenceProfileData.items_and_examples_completed, competenceProfileData.items_and_examples_total)}
          {renderProgress(t('competence-profile.progress.gained-competencies'), '#5CB3E0', competenceProfileData.competencies_gained, competenceProfileData.competencies_total)}

          {isTeacher && (
            <>
              <br />
              <GtnButton
                label={t('competence-profile.base-competencies')}
                actionType="primary"
                onClick={() => {
                  const url = `${moodleUrl}/blocks/exacomp/profoundness.php?courseid=${selectedCourse?.id}`;
                  if (Utils.isEduvidual(moodleUrl)) {
                    window.open(url, '_blank');
                  } else {
                    iframeDialog.open({
                      src: url,
                    });
                  }
                }}
              />
            </>
          )}

          <br />
          <h3 className={styles.title}>{t.withRole('competence-profile.competencies-overview.title')}</h3>

          {initialFormValues && (
            <GtnForm initialValues={initialFormValues}>
              <GtnTreeView showSearchBar={true} searchBarLabel={t('competence-profile.competencies-overview.search')} searchText={searchFilter} onSearchTextChanged={setSearchFilter}>
                {filteredCompetencies?.map((subject) => (
                  <GtnTreeParent
                    id={subject.id}
                    node={
                      <h2
                        className={styles.competenceTitle}
                        dangerouslySetInnerHTML={Utils.highlightSearchText(
                          getTextWithCount(
                            subject.title,
                            subject.topics.reduce(
                              (count, topic) =>
                                count +
                                topic.descriptors.reduce(
                                  (count, descriptor) => count + (descriptor.teacherevaluation > 0 ? 1 : 0) + (descriptor.childdescriptors?.filter((cd) => cd.teacherevaluation > 0).length ?? 0),
                                  0
                                ),
                              0
                            ),
                            subject.topics.reduce((count, topic) => count + topic.descriptors.reduce((count, descriptor) => count + 1 + (descriptor.childdescriptors?.length ?? 0), 0), 0)
                          ),
                          searchFilter
                        )}
                      />
                    }
                    defaultExpanded={filteredCompetencies.length === 1}
                  >
                    {subject.topics?.map((topic) => (
                      <GtnTreeNode
                        id={topic.id}
                        node={
                          <h3
                            className={styles.competenceTitle}
                            dangerouslySetInnerHTML={Utils.highlightSearchText(
                              getTextWithCount(
                                topic.title,
                                topic.descriptors.reduce(
                                  (count, descriptor) => count + (descriptor.teacherevaluation > 0 ? 1 : 0) + (descriptor.childdescriptors?.filter((cd) => cd.teacherevaluation > 0).length ?? 0),
                                  0
                                ),
                                topic.descriptors.reduce((count, descriptor) => count + 1 + (descriptor.childdescriptors?.length ?? 0), 0)
                              ),
                              searchFilter
                            )}
                          />
                        }
                      >
                        {topic.descriptors?.map((descriptor) => {
                          if (descriptor.childdescriptors?.length) {
                            return descriptor.childdescriptors.map((childDescriptor) => (
                              <GtnTreeNode
                                id={descriptor.id}
                                node={
                                  <>
                                    <h3 className={styles.competenceTitle} dangerouslySetInnerHTML={Utils.highlightSearchText(descriptor.title, searchFilter)} />
                                    {renderGrading(descriptor)}
                                  </>
                                }
                              >
                                {renderDescriptor(childDescriptor)}
                              </GtnTreeNode>
                            ));
                          } else {
                            return renderDescriptor(descriptor);
                          }
                        })}
                      </GtnTreeNode>
                    ))}
                  </GtnTreeParent>
                ))}
              </GtnTreeView>
            </GtnForm>
          )}

          {!filteredCompetencies?.length && <p>{t('list-empty')}</p>}
        </>
      )}
    </>
  );

  return (
    <CourseStudentSelectorSidebar selectionMode="single-student-only">
      {isTeacher ? (selectedStudent != null ? mainContent : null) : mainContent}
      <GtnSnackbar textResId="competence-profile.competencies-overview.error-grading-descriptor" open={gradingErrorSnackbarOpen} onClose={() => setGradingErrorSnackbarOpened(false)} />
      <iframeDialog.Component title={t('competence-profile.base-competencies')} />
    </CourseStudentSelectorSidebar>
  );
}
