import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import React, { useState } from 'react';
import * as Yup from 'yup';
import styles from './AddPortfolioTextBlockDialog.module.scss';
import { enum_item_headline_text } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';

export interface AddPortfolioTextBlockDialogProps extends GtnDialogProps {
  viewid: number;
}

interface PortfolioTextBlockFormValue {
  title: string;
  text?: string;
}

export default function AddPortfolioTextBlockDialog(props: AddPortfolioTextBlockDialogProps) {
  const t = useAppTranslation();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);

  const [formValues, setFormValues] = useState<PortfolioTextBlockFormValue>(
    {
      title: ''
    }
  );
  const [error, setError] = React.useState<any>(null);

  const validationSchema = Yup.object().shape<PortfolioTextBlockFormValue>({
    title: Yup.string().required().label(t('portfolios.title')),
    text: Yup.string().label(t('portfolios.text')),
  });

  const onSave = async (values: Partial<PortfolioTextBlockFormValue>, formHelper) => {
    setError(null);

    try {
        await moodleAPI.block_exaport_view_block_add({
        viewid: props.viewid,
        itemid: 0,
        text: values.text,
        title: values.title!,
        type: enum_item_headline_text.TEXT
      });

      props.onClose?.();
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  return (
    <GtnDialog {...props} title={t('portfolios.add-text')}>
      <GtnForm initialValues={formValues} onSubmit={onSave} validationSchema={validationSchema} className={styles.dialogContentContainer}>
        {(formHelper) => (
          <>
            <div className={styles.scrollContainer}>
              <GtnTextField name="title" label={t('portfolios.title')} />
              <GtnTextField name="text" label={t('portfolios.text')} multiline rows={4} />
            </div>

            <div className={styles.submitContainer}>
              <p>{error}</p>

              <GtnButton
                type="button"
                actionType="primary"
                label={t('save')}
                disabled={!formHelper.dirty}
                onClick={async () => {
                  await formHelper.setValues({ ...formHelper.values }, true);
                  await formHelper.submitForm();
                }}
              />
            </div>
          </>
        )}
      </GtnForm>
    </GtnDialog>
  );
}
