import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnSelectionIndicator } from '@gtn/common/components/gtn-selection-indicator/GtnSelectionIndicator.component';
import LoadingContainerV2 from '@gtn/common/components/loading-container/LoadingContainerV2';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import styles from './ChoosePortfolioItemsDialog.module.scss';
import { block_exaport_get_all_user_items_returns_item_items } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';
import { ArchiveOutlined, DescriptionOutlined, ImageOutlined, InsertDriveFileOutlined, NotesOutlined, PictureAsPdfOutlined, SlideshowOutlined, TableChartOutlined } from '@material-ui/icons';

interface ChoosePortfolioItemsDialogProps extends GtnDialogProps {
  onSelected(itemIds: number[]): void;
}

const fileIcons = {
  image: <ImageOutlined />,
  pdf: <PictureAsPdfOutlined />,
  doc: <DescriptionOutlined />,
  docx: <DescriptionOutlined />,
  xls: <TableChartOutlined />,
  xlsx: <TableChartOutlined />,
  ppt: <SlideshowOutlined />,
  pptx: <SlideshowOutlined />,
  txt: <NotesOutlined />,
  zip: <ArchiveOutlined />,
  rar: <ArchiveOutlined />,
  csv: <TableChartOutlined />,
  default: <InsertDriveFileOutlined />,
};

export default function ChoosePortfolioItemsDialog(props: ChoosePortfolioItemsDialogProps) {
  const t = useAppTranslation();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);

  const { data: itemCategories, progressState: itemCategoriesProgressState, mutate: reloadItems } = useAPI(moodleAPI.block_exaport_get_all_user_items);

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const items = useMemo(() => {
    return itemCategories?.flatMap((category) => category.items ?? []);
  }, [itemCategories]);

  function toggleSelectedItem(itemId: number) {
    if (selectedItemIds.includes(itemId)) {
      setSelectedItemIds(selectedItemIds.filter((id) => id !== itemId));
    } else {
      setSelectedItemIds([...selectedItemIds, itemId]);
    }
  }

  function renderSubtitle(block: block_exaport_get_all_user_items_returns_item_items) {
    return block.files.map((file, index) => {
      return (
        <div>
          {fileIcon(file.filename)} {filenameWoExt(file.filename)}
          {index < block.files.length - 1 && <span style={{ fontSize: '2rem', lineHeight: '0.5' }}>&middot;</span>}
        </div>
      );
    });
  }

  function filenameWoExt(filename: string) {
    return filename.split('.')[0];
  }

  function fileIcon(filename: string) {
    if (!filename) {
      return fileIcons.default;
    }

    const extension = filename.split('.').pop()?.toLowerCase();
    if (!extension) {
      return fileIcons.default;
    }

    const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'tiff'];
    if (imageExtensions.includes(extension)) {
      return fileIcons['image'];
    }

    return fileIcons[extension] || fileIcons.default;
  }

  return (
    <GtnDialog {...props} title={t('portfolios.add-items')}>
      <div className={classNames(styles.dialogContentContainer)}>
        <LoadingContainerV2 state={itemCategoriesProgressState} onRetryClick={reloadItems} className={styles.portfolioItems}>
          {items?.map((item) => (
            <div className={styles.itemContainer} onClick={() => toggleSelectedItem(item.id)}>
              <div>
                <p>{item.name}</p>
                <p className={styles.subTitle}>
                  <span title={item.files.map((f) => f.filename).join(' - ') ?? ''}>{renderSubtitle(item)}</span>
                </p>
              </div>
              <GtnSelectionIndicator isSelected={selectedItemIds.includes(item.id)} style={{ margin: '0 12px' }} />
            </div>
          ))}
        </LoadingContainerV2>

        <div className={styles.submitContainer}>
          <p></p>

          <GtnButton type="button" actionType="primary" label={t('portfolios.add-items')} disabled={selectedItemIds.length === 0} onClick={() => props.onSelected(selectedItemIds)} />
        </div>
      </div>
    </GtnDialog>
  );
}
