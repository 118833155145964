export enum DakoraRoutingPaths {
  LEARNING_PLANS = 'learning-plans',
  QUIZZES = 'quizzes',
  COMPETENCE_GRIDS = 'competence-grids',
  LEARNING_DIARY = 'learning-diary',
  LEARNING_PATHS = 'learning-paths',
  PORTFOLIOS = 'portfolios',
  REPORTS = 'reports',
  ASSIGNMENT_GRADING = 'assignment-grading',
  EXPERIENCE_LEVEL_SETTINGS = 'experience-level-settings',
}
