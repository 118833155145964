import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import React, { useState } from 'react';
import * as Yup from 'yup';
import styles from './CreateEditPortfolioDialog.module.scss';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';

export interface CreateEditPortfolioDialogProps extends GtnDialogProps {
  portfolio?: { id: number; name: string; description?: string };
}

interface PortfolioFormValue {
  name: string;
  description?: string;
  externaccess?: boolean;
}

export default function CreateEditPortfolioDialog(props: CreateEditPortfolioDialogProps) {
  const t = useAppTranslation();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);

  const [formValues, setFormValues] = useState<PortfolioFormValue>(
    props.portfolio ?? {
      name: '',
    }
  );
  const [error, setError] = React.useState<any>(null);

  const validationSchema = Yup.object().shape<PortfolioFormValue>({
    name: Yup.string().required().label(t('portfolios.title')),
    description: Yup.string().label(t('portfolios.description')),
    externaccess: Yup.boolean().label(t('porfolios.externalAccess')),
  });

  const onSave = async (values: Partial<PortfolioFormValue>, formHelper) => {
    setError(null);

    try {
      if (props.portfolio == null) {
        await moodleAPI.block_exaport_view_add({
          name: values.name!,
          description: values.description ?? '',
        });
      } else {
        await moodleAPI.block_exaport_view_update({ id: props.portfolio.id, name: values.name!, description: values.description!, externaccess: values.externaccess });
      }

      props.onClose?.();
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  return (
    <GtnDialog {...props} title={t('portfolios.add')}>
      <GtnForm initialValues={formValues} onSubmit={onSave} validationSchema={validationSchema} className={styles.dialogContentContainer}>
        {(formHelper) => (
          <>
            <div className={styles.scrollContainer}>
              <GtnTextField name="name" label={t('portfolios.title')} />
              <GtnTextField name="description" label={t('portfolios.description')} multiline rows={4} />
              {props.portfolio && <GtnCheckbox name="externaccess" label={t('portfolios.activate-link-sharing')} />}
            </div>

            <div className={styles.submitContainer}>
              <p>{error}</p>

              <GtnButton
                type="button"
                actionType="primary"
                label={t('save')}
                disabled={!formHelper.dirty}
                onClick={async () => {
                  await formHelper.setValues({ ...formHelper.values }, true);
                  await formHelper.submitForm();
                }}
              />
            </div>
          </>
        )}
      </GtnForm>
    </GtnDialog>
  );
}
